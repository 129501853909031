<script setup lang="ts">
const { t } = useI18n()

const localePath = useLocalePath()

const { data: references, status } = await useLazyAsyncData(() =>
  createReferencesRepository().getAll()
)
</script>

<template>
  <section class="bg-neutral-50 pb-16 pt-16 sm:pb-52">
    <div class="container">
      <div class="mb-10 text-center">
        <NuxtLink
          class="border-b border-black no-underline"
          :to="
            localePath({
              name: 'references',
            })
          "
        >
          {{ t('pages.references.label') }}
        </NuxtLink>
      </div>
    </div>
    <UiCarousel
      :opts="{
        align: 'start',
        dragFree: true,
      }"
    >
      <UiCarouselPrevious class="absolute left-2 top-1/2 z-10 text-white">
        <Icon size="30" name="bi:arrow-left" />
      </UiCarouselPrevious>
      <UiCarouselContent class="gap-4">
        <template v-if="status === 'pending'">
          <UiCarouselItem class="basis-auto" v-for="i in 10" :key="i">
            <UiSkeletonReferencesCard dark />
          </UiCarouselItem>
        </template>
        <template v-else>
          <UiCarouselItem
            class="basis-auto"
            v-for="reference in references?.items"
            :key="reference.nid"
          >
            <ReferencesCard
              class="!w-[354px] sm:!w-[500px]"
              :reference="reference"
            />
          </UiCarouselItem>
        </template>
      </UiCarouselContent>
      <UiCarouselNext class="absolute right-2 top-1/2 z-10 text-white">
        <Icon size="30" name="bi:arrow-right" />
      </UiCarouselNext>
    </UiCarousel>
  </section>
</template>

<style>
div [aria-disabled='true'] {
  visibility: hidden;
}
</style>
